/*
 *  Radius
*/
/*
 * Config
 * @ Variables
 *
*/
/*
 *  Use Defaults
*/
/*
 *  FWAD base Font size
*/
/*
 * H1
*/
/*
 * header fonts mobile devices
 */
/*
 * header fonts tablets(portrait)
 */
/*
 * header fonts tablets(landscape) and small desktop
 */
/*
 * header fonts large desktop
 */
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-1
*/
/*/*
 * body-2
*/
/*
 * body-3
*/
/* media carousel overlay */
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-ar-1
*/
/*/*
 * body-ar-2
*/
/*
 * body-ar-3
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-ru-1
*/
/*/*
 * body-ru-2
*/
/*
 * body-ru-3
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-zh-1
*/
/*/*
 * body-zh-2
*/
/*
 * body-zh-3
*/
/*
* z-index variables
*/
/*
* form placeholder color variables
*/
/*
 * Background Image
 */
/*
  * Line height font
  */
/*
  * Border Radius
  */
/*
  * Box Sizing
  */
/*
  * Box Shadow
  */
/*
  * Border Radius Separate
  */
/*
  * Desktop (PC's)
  */
/*
  * Wide screen Desktop (PC's)
  */
/*
  * Wide screen Desktop (PC's)
  */
/*
  * large screen Desktop (PC's)
  */
/*
  * Wide screen Devices
  */
/*
  * upto Tablet
  */
/*
  * Tablet
  */
/*
  * TabletOnly
  */
/*
  * Mobile
  */
/*
  * Transition
     usage example
       a{
       color: gray;
       @include transition(color .3s ease);
       &:hover {
         color: black;
       }
 }
  */
/*
  * Opacity
  */
/*
  * Clearfix
  */
/*
  * Configure vendor
  */
/*
  * Transform
  */
/**
 *   Use Arrow inside the :after and :before pseudo elements
 *   and change the position as per needed
 *   @include arrows('right', 7px, 7px, #000);
 **/
/**
 *   Background gradient mixin
 **/
/*
  * Flex
  */
/*
 * @ Fonts
 *
*/
/*
 * Base
 * @ Colors
 *
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * Base
 * @ Text
 *
*/
.c-hero-component {
  margin-bottom: 40px;
  max-width: none;
  /* Home Variant ==> .home */
  /* Subpage Variant ==> .left */
  /* full view port hero image with gradient effect applied when 'applyGradient' == ture */
}
.c-hero-component.smart-hero-carousel-view.dom-rendering .c-media-carousel-wrapper {
  background-color: #000000;
}
.c-hero-component.smart-hero-carousel-view.dom-rendering .c-media-carousel-wrapper .media-carousel--content .w-media-content {
  margin-bottom: 71px;
}
.c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .w-media-content .c-media-carousel .w--middle.dom-pending .slick-initialized .slick-list .slick-track {
  display: flex;
}
.c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .w-media-content .c-media-carousel .w--middle.dom-pending .slick-initialized .slick-list .slick-track .slick-slide[data-index="-1"] {
  display: none;
}
.c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .w-media-content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized {
  background-color: #000000;
}
.c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .w-media-content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-desc-container {
  max-width: none;
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .w-media-content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-desc-container {
    padding: 0 30px 30px;
    width: 100%;
    max-width: 100%;
  }
}
.c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .w-media-content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-desc-container .media-carousel-item--title {
  font-family: Norwester-Onload, Open Sans, Arial, sans-serif;
  padding-top: 24px;
  letter-spacing: normal;
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .w-media-content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-desc-container .media-carousel-item--title {
    letter-spacing: 0.5px;
  }
}
.lang-ar .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .w-media-content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-desc-container .media-carousel-item--title {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
}
.c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .w-media-content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-desc-container .media-carousel-item--bodycopy {
  padding-top: 16px;
  font-size: 1rem;
  line-height: 26px;
}
.lang-ar .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .w-media-content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-desc-container .media-carousel-item--bodycopy {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.c-hero-component .editorial-grid-tooltip-wrapper + .editorial-text {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .editorial-grid-tooltip-wrapper + .editorial-text {
    margin-top: 55px;
  }
}
.c-hero-component .hero-banner-container {
  background-color: #000000;
  margin-bottom: 30px;
  /* Small devices (tablets, 768px and up) */
  position: relative;
}
@media (min-width: 768px) {
  .c-hero-component .hero-banner-container {
    margin: 0;
    max-width: 100%;
    margin-bottom: 30px;
  }
}
.c-hero-component .hero-banner-container .contents {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .hero-banner-container .contents {
    padding-bottom: 7px;
  }
  .c-hero-component .hero-banner-container .contents:before {
    content: "";
    position: absolute;
    bottom: -15px;
    left: 0;
    background-color: #ffec00;
    width: 65px;
    height: 2px;
  }
}
.c-hero-component .hero-banner-container .hero-banner-title {
  font-family: "Norwester", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 33px;
  line-height: 39px;
  letter-spacing: 0.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  color: #000000;
}
@media (min-width: 768px) {
  .c-hero-component .hero-banner-container .hero-banner-title {
    font-size: 33px;
    line-height: 39px;
    letter-spacing: 0.5px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .hero-banner-container .hero-banner-title {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component .hero-banner-container .hero-banner-title {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
body.lang-ar .c-hero-component .hero-banner-container .hero-banner-title {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 33px;
  line-height: 39px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .c-hero-component .hero-banner-container .hero-banner-title {
    font-size: 33px;
    line-height: 39px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .c-hero-component .hero-banner-container .hero-banner-title {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .c-hero-component .hero-banner-container .hero-banner-title {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
body.lang-ru .c-hero-component .hero-banner-container .hero-banner-title {
  font-family: "Noto Sans Black", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 39px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .c-hero-component .hero-banner-container .hero-banner-title {
    font-size: 30px;
    line-height: 39px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .c-hero-component .hero-banner-container .hero-banner-title {
    font-size: 42px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .c-hero-component .hero-banner-container .hero-banner-title {
    font-size: 42px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
body.lang-zh .c-hero-component .hero-banner-container .hero-banner-title {
  font-family: "Norwester", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 700;
  font-size: 33px;
  line-height: 39px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .c-hero-component .hero-banner-container .hero-banner-title {
    font-size: 33px;
    line-height: 39px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .c-hero-component .hero-banner-container .hero-banner-title {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .c-hero-component .hero-banner-container .hero-banner-title {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
.c-hero-component .hero-image {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}
.c-hero-component .hero-image picture {
  display: block;
  margin: 0 auto;
}
.c-hero-component .hero-image picture:after {
  content: "";
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
}
.c-hero-component .hero-detail-container {
  width: 95.2380952381%;
  max-width: 731.4285714286px;
  padding-left: 2.380952381%;
  padding-right: 2.380952381%;
  background: #ffffff left bottom/330px 341px no-repeat;
  color: #333333;
  height: auto;
  margin-top: -130px;
  padding: 30px;
  position: relative;
  text-align: center;
  z-index: 2;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .c-hero-component .hero-detail-container {
    width: 38.9784946237%;
    max-width: 399.1397849462px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    margin: 0 auto;
    margin-left: 8.3333333333%;
    padding: 0;
    padding-right: 40px;
    top: 50%;
    background-color: transparent;
    box-shadow: none;
    text-align: left;
    transform: translateY(-50%);
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .hero-detail-container {
    width: 39.6341463415%;
    max-width: 541.4024390244px;
    padding-left: 1.0162601626%;
    padding-right: 1.0162601626%;
    margin: 0 auto;
    margin-left: 8.3333333333%;
    padding-right: 62px;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component .hero-detail-container {
    width: 39.8484848485%;
    max-width: 1296px;
    padding-left: 0.9090909091%;
    padding-right: 0.9090909091%;
    margin: 0 auto;
    margin-left: 8.3333333333%;
    padding-right: 86px;
  }
}
.c-hero-component .hero-detail-container .hero-banner-shortTitle {
  font-family: "Norwester", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  color: #e50000;
  margin-bottom: 0;
  padding-bottom: 9px;
  position: relative;
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-hero-component .hero-detail-container .hero-banner-shortTitle {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .hero-detail-container .hero-banner-shortTitle {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component .hero-detail-container .hero-banner-shortTitle {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
body.lang-ar .c-hero-component .hero-detail-container .hero-banner-shortTitle {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .c-hero-component .hero-detail-container .hero-banner-shortTitle {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .c-hero-component .hero-detail-container .hero-banner-shortTitle {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .c-hero-component .hero-detail-container .hero-banner-shortTitle {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
body.lang-ru .c-hero-component .hero-detail-container .hero-banner-shortTitle {
  font-family: "Noto Sans Black", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .c-hero-component .hero-detail-container .hero-banner-shortTitle {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .c-hero-component .hero-detail-container .hero-banner-shortTitle {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .c-hero-component .hero-detail-container .hero-banner-shortTitle {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
body.lang-zh .c-hero-component .hero-detail-container .hero-banner-shortTitle {
  font-family: "Norwester", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .c-hero-component .hero-detail-container .hero-banner-shortTitle {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .c-hero-component .hero-detail-container .hero-banner-shortTitle {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .c-hero-component .hero-detail-container .hero-banner-shortTitle {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .hero-detail-container .hero-banner-shortTitle {
    padding-bottom: 7px;
  }
}
.c-hero-component .hero-detail-container .hero-banner-desc {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  color: #333333;
  padding-top: 7px;
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-hero-component .hero-detail-container .hero-banner-desc {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .hero-detail-container .hero-banner-desc {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component .hero-detail-container .hero-banner-desc {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
body.lang-ar .c-hero-component .hero-detail-container .hero-banner-desc {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .c-hero-component .hero-detail-container .hero-banner-desc {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .c-hero-component .hero-detail-container .hero-banner-desc {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .c-hero-component .hero-detail-container .hero-banner-desc {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
body.lang-ru .c-hero-component .hero-detail-container .hero-banner-desc {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .c-hero-component .hero-detail-container .hero-banner-desc {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .c-hero-component .hero-detail-container .hero-banner-desc {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .c-hero-component .hero-detail-container .hero-banner-desc {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
body.lang-zh .c-hero-component .hero-detail-container .hero-banner-desc {
  font-family: "Open Sans", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .c-hero-component .hero-detail-container .hero-banner-desc {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .c-hero-component .hero-detail-container .hero-banner-desc {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .c-hero-component .hero-detail-container .hero-banner-desc {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .hero-detail-container .hero-banner-desc {
    padding-top: 19px;
  }
}
.c-hero-component .hero-detail-container .hero-banner-desc h2 {
  font-family: "Norwester", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .c-hero-component .hero-detail-container .hero-banner-desc h2 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.5px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .hero-detail-container .hero-banner-desc h2 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.5px;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component .hero-detail-container .hero-banner-desc h2 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.5px;
  }
}
body.lang-ar .c-hero-component .hero-detail-container .hero-banner-desc h2 {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .c-hero-component .hero-detail-container .hero-banner-desc h2 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.5px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .c-hero-component .hero-detail-container .hero-banner-desc h2 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.5px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .c-hero-component .hero-detail-container .hero-banner-desc h2 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.5px;
  }
}
body.lang-ru .c-hero-component .hero-detail-container .hero-banner-desc h2 {
  font-family: "Noto Sans Black", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .c-hero-component .hero-detail-container .hero-banner-desc h2 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.5px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .c-hero-component .hero-detail-container .hero-banner-desc h2 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.5px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .c-hero-component .hero-detail-container .hero-banner-desc h2 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.5px;
  }
}
body.lang-zh .c-hero-component .hero-detail-container .hero-banner-desc h2 {
  font-family: "Norwester", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .c-hero-component .hero-detail-container .hero-banner-desc h2 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.5px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .c-hero-component .hero-detail-container .hero-banner-desc h2 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.5px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .c-hero-component .hero-detail-container .hero-banner-desc h2 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.5px;
  }
}
.c-hero-component .hero-detail-container .hero-banner-desc p {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  -ms-word-wrap: break-word;
}
@media (min-width: 768px) {
  .c-hero-component .hero-detail-container .hero-banner-desc p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .hero-detail-container .hero-banner-desc p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component .hero-detail-container .hero-banner-desc p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
body.lang-ar .c-hero-component .hero-detail-container .hero-banner-desc p {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .c-hero-component .hero-detail-container .hero-banner-desc p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .c-hero-component .hero-detail-container .hero-banner-desc p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .c-hero-component .hero-detail-container .hero-banner-desc p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
body.lang-ru .c-hero-component .hero-detail-container .hero-banner-desc p {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .c-hero-component .hero-detail-container .hero-banner-desc p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .c-hero-component .hero-detail-container .hero-banner-desc p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .c-hero-component .hero-detail-container .hero-banner-desc p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
body.lang-zh .c-hero-component .hero-detail-container .hero-banner-desc p {
  font-family: "Open Sans", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .c-hero-component .hero-detail-container .hero-banner-desc p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .c-hero-component .hero-detail-container .hero-banner-desc p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .c-hero-component .hero-detail-container .hero-banner-desc p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
.c-hero-component .quick-links {
  position: absolute;
  text-align: center;
  width: 100%;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-hero-component .quick-links {
    position: relative;
    padding: 10px;
    background-color: #000000;
    top: -30px;
    margin-bottom: -30px;
  }
}
.c-hero-component .quick-links .quick-links-band {
  background: #ffffff;
  border-color: #d3d3d3;
}
.c-hero-component .quick-links .quick-links-band-item__link__content__text span.ldc-custom {
  font-family: "Norwester", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  color: #000000;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: 0.5px;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-hero-component .quick-links .quick-links-band-item__link__content__text span.ldc-custom {
    font-style: normal;
    font-size: 24px;
    line-height: 24px;
  }
}
.lang-ar .c-hero-component .quick-links .quick-links-band-item__link__content__text span.ldc-custom {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: normal;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .lang-ar .c-hero-component .quick-links .quick-links-band-item__link__content__text span.ldc-custom {
    line-height: 30px;
  }
}
.c-hero-component .quick-links .quick-links-band-item__link__content__text span.short-title {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  color: #333333;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.1px;
  padding-top: 0;
}
.lang-ar .c-hero-component .quick-links .quick-links-band-item__link__content__text span.short-title {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  letter-spacing: normal;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .lang-ar .c-hero-component .quick-links .quick-links-band-item__link__content__text span.short-title {
    font-size: 16px;
    line-height: 30px;
  }
}
.c-hero-component .quick-links .quick-links-band-item__link__content__arrow:after {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAKCAYAAAC5Sw6hAAAAAXNSR0IArs4c6QAAAOBJREFUKBWlkD2KQkEQhD8VV0PZwEQNBMFYvIDRpi4YexNDj2Iq6AF28QJiZCAIgj+JgfGCoFYpK/MeykNsKKa7urpmppnDB2+GPdIZyK3haweVV/0841l7pDy8hbKOvtMjDKqwUH4WHkVqBfUsdNUsCT0Nb69GVi+hmIex0qYwFWY3moNOx6dQExrCVfMHbRF71dyNXGwkFjEUWq6fhZ46ETraxf8lpEOxGxYIvyEf5u7FTcJ+JPfLtLcfLfMcwpx7EXFSETdLMonsKG5uM/19ZP4E3+FO4trEegUFI0l4ASyjQell5lQGAAAAAElFTkSuQmCC);
  content: "";
  width: 18px;
  height: 10px;
  right: 2px;
  transform: rotate(-90deg);
  border-style: none;
}
.lang-ar .c-hero-component .quick-links .quick-links-band-item__link__content__arrow:after {
  transform: rotate(90deg);
  right: -15px;
}
.c-hero-component.home {
  margin-bottom: 100px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.home {
    margin-bottom: 40px;
  }
}
.c-hero-component.home .hero-image {
  height: auto;
  max-width: 100%;
  margin: 0 auto;
}
.c-hero-component.home .hero-image picture img {
  height: calc(100vh - 250px);
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.home .hero-image picture img {
    height: auto;
  }
}
.c-hero-component.home .hero-banner-container .hero-detail-container.w--content {
  bottom: 5px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.home .hero-banner-container .hero-detail-container.w--content {
    bottom: auto;
  }
}
.c-hero-component.home .hero-banner-container .contents {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.home .hero-banner-container .contents:before {
    content: none;
  }
}
.c-hero-component.home .hero-banner-container .hero-detail-container {
  padding-bottom: 40px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.home .hero-banner-container .hero-detail-container {
    position: absolute;
    transform: translateY(-50%);
    background: none;
  }
}
.c-hero-component.home .hero-banner-container .hero-banner-shortTitle {
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.home .hero-banner-container .hero-banner-shortTitle {
    padding-bottom: 6px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.home .hero-banner-container .hero-banner-shortTitle {
    padding-bottom: 7px;
  }
}
.c-hero-component.home .editorial-grid-tooltip-wrapper {
  display: none;
}
.c-hero-component.home.v-hotel-widget .hero-detail-container {
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.home.v-hotel-widget .hero-detail-container {
    top: 42%;
    transform: translateY(-13%);
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component.home.v-hotel-widget .hero-detail-container {
    top: 42%;
    transform: translateY(-24%);
  }
}
.c-hero-component.left.dark .hero-banner-shortTitle, .c-hero-component.center.dark .hero-banner-shortTitle, .c-hero-component.right.dark .hero-banner-shortTitle {
  color: #000000;
}
.c-hero-component.left.dark .hero-banner-title,
.c-hero-component.left.dark .hero-banner-desc, .c-hero-component.center.dark .hero-banner-title,
.c-hero-component.center.dark .hero-banner-desc, .c-hero-component.right.dark .hero-banner-title,
.c-hero-component.right.dark .hero-banner-desc {
  color: #000000;
}
.c-hero-component.left.light.light .hero-banner-shortTitle, .c-hero-component.center.light.light .hero-banner-shortTitle, .c-hero-component.right.light.light .hero-banner-shortTitle {
  color: #ffffff;
}
.c-hero-component.left.light.light .hero-banner-title,
.c-hero-component.left.light.light .hero-banner-desc, .c-hero-component.center.light.light .hero-banner-title,
.c-hero-component.center.light.light .hero-banner-desc, .c-hero-component.right.light.light .hero-banner-title,
.c-hero-component.right.light.light .hero-banner-desc {
  color: #ffffff;
}
.c-hero-component.left.component, .c-hero-component.center.component, .c-hero-component.right.component {
  margin-bottom: 27px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.left.component, .c-hero-component.center.component, .c-hero-component.right.component {
    margin-bottom: 60px;
  }
}
.c-hero-component.left .hero-image, .c-hero-component.center .hero-image, .c-hero-component.right .hero-image {
  width: 100%;
  max-width: 100%;
}
.c-hero-component.left .hero-banner-container, .c-hero-component.center .hero-banner-container, .c-hero-component.right .hero-banner-container {
  height: auto;
}
.c-hero-component.left .hero-banner-container .hero-detail-container, .c-hero-component.center .hero-banner-container .hero-detail-container, .c-hero-component.right .hero-banner-container .hero-detail-container {
  background-color: transparent;
  position: absolute;
  margin: 25px auto;
  padding: 0;
  top: auto;
  bottom: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.left .hero-banner-container .hero-detail-container, .c-hero-component.center .hero-banner-container .hero-detail-container, .c-hero-component.right .hero-banner-container .hero-detail-container {
    bottom: auto;
    width: 38.9784946237%;
    max-width: 399.1397849462px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    margin: 0;
    margin-left: 8.3333333333%;
    top: 50%;
    transform: translateY(-50%);
    padding-right: 40px;
    background: none;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.left .hero-banner-container .hero-detail-container, .c-hero-component.center .hero-banner-container .hero-detail-container, .c-hero-component.right .hero-banner-container .hero-detail-container {
    width: 39.6341463415%;
    max-width: 541.4024390244px;
    margin-left: 1.0162601626%;
    margin-right: 1.0162601626%;
    margin: 0;
    margin-left: 8.3333333333%;
    padding-right: 62px;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component.left .hero-banner-container .hero-detail-container, .c-hero-component.center .hero-banner-container .hero-detail-container, .c-hero-component.right .hero-banner-container .hero-detail-container {
    width: 39.8484848485%;
    max-width: 1296px;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    margin: 0;
    margin-left: 8.3333333333%;
    padding-right: 86px;
  }
}
.c-hero-component.left .editorial-grid-tooltip-wrapper, .c-hero-component.center .editorial-grid-tooltip-wrapper, .c-hero-component.right .editorial-grid-tooltip-wrapper {
  display: block;
}
.c-hero-component.center .hero-detail-container {
  position: absolute;
  margin: 0 auto;
}
.c-hero-component.center .hero-detail-container.w--content {
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.center .hero-detail-container.w--content {
    margin: 0 auto;
    padding: 0;
  }
}
.c-hero-component.center .hero-banner-container .contents {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.center .hero-banner-container .contents:before {
    left: calc(50% - 32px);
  }
}
.c-hero-component.center .hero-banner-title,
.c-hero-component.center .hero-banner-desc,
.c-hero-component.center .hero-banner-shortTitle {
  text-align: center;
}
.c-hero-component.right {
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.right .hero-detail-container {
    right: 0;
    left: auto;
    /* Small devices (tablets, 768px and up) */
  }
  .c-hero-component.right .hero-detail-container.w--content {
    /* Small devices (tablets, 768px and up) */
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .c-hero-component.right .hero-detail-container.w--content {
    padding: 0;
    margin-right: 8.33333%;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .c-hero-component.right .hero-detail-container {
    text-align: right;
  }
}
@media (min-width: 768px) {
  .c-hero-component.right .hero-banner-title,
  .c-hero-component.right .hero-banner-desc,
  .c-hero-component.right .hero-banner-shortTitle {
    text-align: right;
  }
}
.c-hero-component.right .hero-banner-container .contents {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.right .hero-banner-container .contents:before {
    left: auto;
    right: 0;
  }
}
.c-hero-component .short-title {
  display: block;
  padding-top: 24px;
  color: #ffffff;
}
.c-hero-component .short-title .body-copy-3 {
  font-family: "Norwester", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  text-align: center;
}
@media (min-width: 768px) {
  .c-hero-component .short-title .body-copy-3 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .short-title .body-copy-3 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component .short-title .body-copy-3 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
body.lang-ar .c-hero-component .short-title .body-copy-3 {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .c-hero-component .short-title .body-copy-3 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .c-hero-component .short-title .body-copy-3 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .c-hero-component .short-title .body-copy-3 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
body.lang-ru .c-hero-component .short-title .body-copy-3 {
  font-family: "Noto Sans Black", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .c-hero-component .short-title .body-copy-3 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .c-hero-component .short-title .body-copy-3 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .c-hero-component .short-title .body-copy-3 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
body.lang-zh .c-hero-component .short-title .body-copy-3 {
  font-family: "Norwester", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .c-hero-component .short-title .body-copy-3 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .c-hero-component .short-title .body-copy-3 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .c-hero-component .short-title .body-copy-3 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
.c-hero-component.light {
  /* Medium devices (desktops, 1024px and up) */
}
.c-hero-component.light .hero-banner-title,
.c-hero-component.light .hero-banner-desc,
.c-hero-component.light .hero-banner-shortTitle {
  color: #ffffff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.light .hero-banner-container .hero-banner-shortTitle:before {
    background-color: #ffffff;
  }
}
.c-hero-component.home.light .hero-banner-shortTitle {
  color: #ffffff;
}
.c-hero-component.home.light .hero-banner-title,
.c-hero-component.home.light .hero-banner-desc {
  color: #ffffff;
}
.c-hero-component.home.dark .hero-banner-shortTitle {
  color: #000000;
}
.c-hero-component.home.dark .hero-banner-title,
.c-hero-component.home.dark .hero-banner-desc {
  color: #000000;
}
.c-hero-component.v-gradient-filter {
  max-width: none;
}
.c-hero-component.v-gradient-filter .hero-banner-container {
  background: #000000;
}
.c-hero-component.v-gradient-filter .hero-image {
  height: auto;
  max-width: 100%;
  margin: 0 auto;
}
.c-hero-component.v-gradient-filter .hero-image picture:after {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  top: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.v-gradient-filter .hero-image picture:after {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 100%);
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.v-gradient-filter .hero-image picture:after {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 100%);
  }
}
.c-hero-component.v-gradient-filter .hero-image picture img {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.v-gradient-filter .hero-image picture img {
    height: auto;
  }
}
.c-hero-component.v-gradient-filter .hero-banner-container .hero-detail-container {
  bottom: 0;
  top: auto;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.v-gradient-filter .hero-banner-container .hero-detail-container {
    top: 50%;
    bottom: auto;
  }
}
.c-hero-component.v-gradient-filter.hero-carousel .c-media-carousel .c-media-carousel-view-container {
  max-width: initial;
  height: auto;
}
.c-hero-component.v-gradient-filter.hero-carousel .c-media-carousel .c-media-carousel-view-container:after {
  content: "";
  display: block;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  top: 0;
  z-index: 1;
  left: 0;
  max-width: none;
  max-height: none;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.v-gradient-filter.hero-carousel .c-media-carousel .c-media-carousel-view-container:after {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 100%);
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.v-gradient-filter.hero-carousel .c-media-carousel .c-media-carousel-view-container:after {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 100%);
  }
}
.c-hero-component.dark .hero-banner-title,
.c-hero-component.dark .hero-banner-desc {
  color: #000000;
}
.c-hero-component .c-editorial-text .w--content {
  padding: 0;
}
.corporate-enrollment .tenant-fwad .c-hero-component .c-editorial-text .w--content {
  width: 50%;
}
.c-hero-component .c-editorial-text .w--content > h2,
.c-hero-component .c-editorial-text .w--content > h1 {
  font-family: "Norwester", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  font-size: 20px;
  /* Small devices (tablets, 768px and up) */
  color: #e50000;
}
@media (min-width: 768px) {
  .c-hero-component .c-editorial-text .w--content > h2,
  .c-hero-component .c-editorial-text .w--content > h1 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .c-editorial-text .w--content > h2,
  .c-hero-component .c-editorial-text .w--content > h1 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component .c-editorial-text .w--content > h2,
  .c-hero-component .c-editorial-text .w--content > h1 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
body.lang-ar .c-hero-component .c-editorial-text .w--content > h2,
body.lang-ar .c-hero-component .c-editorial-text .w--content > h1 {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .c-hero-component .c-editorial-text .w--content > h2,
  body.lang-ar .c-hero-component .c-editorial-text .w--content > h1 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .c-hero-component .c-editorial-text .w--content > h2,
  body.lang-ar .c-hero-component .c-editorial-text .w--content > h1 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .c-hero-component .c-editorial-text .w--content > h2,
  body.lang-ar .c-hero-component .c-editorial-text .w--content > h1 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
body.lang-ru .c-hero-component .c-editorial-text .w--content > h2,
body.lang-ru .c-hero-component .c-editorial-text .w--content > h1 {
  font-family: "Noto Sans Black", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .c-hero-component .c-editorial-text .w--content > h2,
  body.lang-ru .c-hero-component .c-editorial-text .w--content > h1 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .c-hero-component .c-editorial-text .w--content > h2,
  body.lang-ru .c-hero-component .c-editorial-text .w--content > h1 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .c-hero-component .c-editorial-text .w--content > h2,
  body.lang-ru .c-hero-component .c-editorial-text .w--content > h1 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
body.lang-zh .c-hero-component .c-editorial-text .w--content > h2,
body.lang-zh .c-hero-component .c-editorial-text .w--content > h1 {
  font-family: "Norwester", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .c-hero-component .c-editorial-text .w--content > h2,
  body.lang-zh .c-hero-component .c-editorial-text .w--content > h1 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .c-hero-component .c-editorial-text .w--content > h2,
  body.lang-zh .c-hero-component .c-editorial-text .w--content > h1 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .c-hero-component .c-editorial-text .w--content > h2,
  body.lang-zh .c-hero-component .c-editorial-text .w--content > h1 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media (min-width: 768px) {
  .c-hero-component .c-editorial-text .w--content > h2,
  .c-hero-component .c-editorial-text .w--content > h1 {
    font-size: 20px;
  }
}
.c-hero-component .c-editorial-text .w--content > h2:before,
.c-hero-component .c-editorial-text .w--content > h1:before {
  content: "";
  display: block;
  border-left: 2px solid;
  height: 24px;
  margin-left: 50%;
  margin-bottom: 5px;
}
.c-hero-component .c-editorial-text .w--content > h2:before,
.c-hero-component .c-editorial-text .w--content > h1:before {
  border-left-color: #e50000;
}
.c-hero-component .c-editorial-text .body-copy-1 {
  width: 95.2380952381%;
  max-width: 731.4285714286px;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  margin: 0 auto;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-hero-component .c-editorial-text .body-copy-1 {
    width: 100%;
  }
}
.c-hero-component .c-editorial-text.yas-island .body-copy-1 p {
  position: relative;
  top: 275px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-hero-component .c-editorial-text.yas-island .body-copy-1 p {
    top: 305px;
  }
}
.c-hero-component .editorial-text {
  text-align: center;
}
.c-hero-component .editorial-text .ticket-details {
  margin-top: 30px;
}
.c-hero-component .editorial-text > p {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 700;
  color: #000000;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .c-hero-component .editorial-text > p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .editorial-text > p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component .editorial-text > p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
body.lang-ar .c-hero-component .editorial-text > p {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .c-hero-component .editorial-text > p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .c-hero-component .editorial-text > p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .c-hero-component .editorial-text > p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
body.lang-ru .c-hero-component .editorial-text > p {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .c-hero-component .editorial-text > p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .c-hero-component .editorial-text > p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .c-hero-component .editorial-text > p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
body.lang-zh .c-hero-component .editorial-text > p {
  font-family: "Open Sans", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .c-hero-component .editorial-text > p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .c-hero-component .editorial-text > p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .c-hero-component .editorial-text > p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
.c-hero-component .editorial-text ul {
  margin-bottom: 30px;
  padding: 0 20px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .editorial-text ul {
    padding: 0;
  }
}
.c-hero-component .editorial-text ul li {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  text-align: center;
  margin-bottom: 5px;
  list-style-position: inside;
  list-style-type: none;
}
@media (min-width: 768px) {
  .c-hero-component .editorial-text ul li {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .editorial-text ul li {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component .editorial-text ul li {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
body.lang-ar .c-hero-component .editorial-text ul li {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .c-hero-component .editorial-text ul li {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .c-hero-component .editorial-text ul li {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .c-hero-component .editorial-text ul li {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
body.lang-ru .c-hero-component .editorial-text ul li {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .c-hero-component .editorial-text ul li {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .c-hero-component .editorial-text ul li {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .c-hero-component .editorial-text ul li {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
body.lang-zh .c-hero-component .editorial-text ul li {
  font-family: "Open Sans", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .c-hero-component .editorial-text ul li {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .c-hero-component .editorial-text ul li {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .c-hero-component .editorial-text ul li {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
.c-hero-component .editorial-text ul li:before {
  content: "";
  border: 2px solid #e50000;
  border-top: none;
  border-right: none;
  display: inline-block;
  width: 14px;
  height: 7px;
  position: relative;
  transform: rotate(-45deg);
  background-color: transparent;
}
.c-hero-component .editorial-text ul li:before {
  margin-right: 10px;
  top: -4px;
}
.c-hero-component .editorial-text ul li:last-child {
  margin-bottom: 0;
}
.c-hero-component .editorial-text .body-copy-1 {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .c-hero-component .editorial-text .body-copy-1 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .editorial-text .body-copy-1 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component .editorial-text .body-copy-1 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
body.lang-ar .c-hero-component .editorial-text .body-copy-1 {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .c-hero-component .editorial-text .body-copy-1 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .c-hero-component .editorial-text .body-copy-1 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .c-hero-component .editorial-text .body-copy-1 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
body.lang-ru .c-hero-component .editorial-text .body-copy-1 {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .c-hero-component .editorial-text .body-copy-1 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .c-hero-component .editorial-text .body-copy-1 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .c-hero-component .editorial-text .body-copy-1 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
body.lang-zh .c-hero-component .editorial-text .body-copy-1 {
  font-family: "Open Sans", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .c-hero-component .editorial-text .body-copy-1 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .c-hero-component .editorial-text .body-copy-1 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .c-hero-component .editorial-text .body-copy-1 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
.c-hero-component .editorial-text .body-copy-1 h6 {
  font-family: "Norwester", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  color: #e50000;
}
@media (min-width: 768px) {
  .c-hero-component .editorial-text .body-copy-1 h6 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .editorial-text .body-copy-1 h6 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component .editorial-text .body-copy-1 h6 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
body.lang-ar .c-hero-component .editorial-text .body-copy-1 h6 {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .c-hero-component .editorial-text .body-copy-1 h6 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .c-hero-component .editorial-text .body-copy-1 h6 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .c-hero-component .editorial-text .body-copy-1 h6 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
body.lang-ru .c-hero-component .editorial-text .body-copy-1 h6 {
  font-family: "Noto Sans Black", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .c-hero-component .editorial-text .body-copy-1 h6 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .c-hero-component .editorial-text .body-copy-1 h6 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .c-hero-component .editorial-text .body-copy-1 h6 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
body.lang-zh .c-hero-component .editorial-text .body-copy-1 h6 {
  font-family: "Norwester", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .c-hero-component .editorial-text .body-copy-1 h6 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .c-hero-component .editorial-text .body-copy-1 h6 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .c-hero-component .editorial-text .body-copy-1 h6 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
.c-hero-component .editorial-text .body-copy-1 h2 {
  font-family: "Norwester", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 33px;
  line-height: 39px;
  letter-spacing: 0.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .c-hero-component .editorial-text .body-copy-1 h2 {
    font-size: 33px;
    line-height: 39px;
    letter-spacing: 0.5px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .editorial-text .body-copy-1 h2 {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component .editorial-text .body-copy-1 h2 {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
body.lang-ar .c-hero-component .editorial-text .body-copy-1 h2 {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 33px;
  line-height: 39px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .c-hero-component .editorial-text .body-copy-1 h2 {
    font-size: 33px;
    line-height: 39px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .c-hero-component .editorial-text .body-copy-1 h2 {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .c-hero-component .editorial-text .body-copy-1 h2 {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
body.lang-ru .c-hero-component .editorial-text .body-copy-1 h2 {
  font-family: "Noto Sans Black", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 39px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .c-hero-component .editorial-text .body-copy-1 h2 {
    font-size: 30px;
    line-height: 39px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .c-hero-component .editorial-text .body-copy-1 h2 {
    font-size: 42px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .c-hero-component .editorial-text .body-copy-1 h2 {
    font-size: 42px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
body.lang-zh .c-hero-component .editorial-text .body-copy-1 h2 {
  font-family: "Norwester", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 700;
  font-size: 33px;
  line-height: 39px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .c-hero-component .editorial-text .body-copy-1 h2 {
    font-size: 33px;
    line-height: 39px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .c-hero-component .editorial-text .body-copy-1 h2 {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .c-hero-component .editorial-text .body-copy-1 h2 {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
.c-hero-component .editorial-text .body-copy-1 > p,
.c-hero-component .editorial-text p > p {
  text-align: center;
  margin: 0;
}
.tenant-fwad .c-hero-component.error-component {
  margin-bottom: 0;
}
.tenant-fwad .c-hero-component.error-component .hero-banner-container {
  background: #e70d0d;
}
.tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container {
  margin: 0;
  top: 0;
  padding: 96px 0 120px;
  text-align: center;
  background-color: #e70d0d;
  width: 100%;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container {
    max-width: none;
    position: relative;
    transform: none;
  }
  .tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container:before {
    left: 8px;
  }
  .tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container:after {
    right: 8px;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container {
    padding: 85px 0 102px;
  }
  .tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container .contents::before {
    display: none;
  }
  .tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container:before {
    max-width: 178px;
    max-height: 165px;
    left: 2px;
  }
  .tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container:after {
    max-width: 178px;
    max-height: 165px;
    right: 2px;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container:before {
    left: 8px;
  }
  .tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container:after {
    right: 8px;
  }
}
.tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container .hero-banner-desc > div {
  display: inline-block;
  transform: skew(0deg);
  margin-top: 0;
  background-color: transparent;
}
.tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container .hero-banner-desc > div:before, .tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container .hero-banner-desc > div:after {
  content: none;
}
.tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container .hero-banner-desc > div:hover {
  background-color: transparent;
}
.tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container .hero-banner-desc p {
  width: 95.2380952381%;
  max-width: 731.4285714286px;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  color: #ffffff;
  padding-bottom: 5px;
  max-width: 335px;
  margin: 0 auto;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container .hero-banner-desc p {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container .hero-banner-desc p {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container .hero-banner-desc p {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
body.lang-ar .tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container .hero-banner-desc p {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container .hero-banner-desc p {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container .hero-banner-desc p {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container .hero-banner-desc p {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
body.lang-ru .tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container .hero-banner-desc p {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container .hero-banner-desc p {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container .hero-banner-desc p {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container .hero-banner-desc p {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
body.lang-zh .tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container .hero-banner-desc p {
  font-family: "Open Sans", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container .hero-banner-desc p {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container .hero-banner-desc p {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container .hero-banner-desc p {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media (min-width: 768px) {
  .tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container .hero-banner-desc p {
    width: 47.311827957%;
    max-width: 484.4731182796px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container .hero-banner-desc p {
    width: 47.9674796748%;
    max-width: 655.2357723577px;
    margin-left: 1.0162601626%;
    margin-right: 1.0162601626%;
    max-width: 403px;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-fwad .c-hero-component.error-component .hero-banner-container .hero-detail-container .hero-banner-desc p {
    width: 31.5151515152%;
    max-width: 1296px;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    margin: 0 auto;
  }
}
.tenant-fwad .c-hero-component.error-component .hero-image {
  display: none;
}
.tenant-fwad .c-hero-component.error-component .hero-image:after {
  content: none;
}
.tenant-fwad .c-hero-component.left.component, .tenant-fwad .c-hero-component.center.component, .tenant-fwad .c-hero-component.right.component {
  margin-bottom: 27px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-fwad .c-hero-component.left.component, .tenant-fwad .c-hero-component.center.component, .tenant-fwad .c-hero-component.right.component {
    margin-bottom: 60px;
  }
}
.c-hero-component.article-detail-hero {
  padding-bottom: 16px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.article-detail-hero {
    padding-bottom: 26px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.article-detail-hero {
    padding-bottom: 34px;
  }
}
.c-hero-component.article-detail-hero .hero-banner-container .hero-detail-container {
  width: 95.2380952381%;
  max-width: 731.4285714286px;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  padding-right: 0;
  position: relative;
  bottom: 0;
  transform: translateY(10%);
  margin: 0 auto;
  text-align: center;
}
.c-hero-component.article-detail-hero .hero-banner-container .hero-detail-container:before {
  content: "";
  display: block;
  border-left: 2px solid #e50000;
  height: 24px;
  margin-left: 50%;
  margin-bottom: 5px;
}
.c-hero-component.article-detail-hero .hero-banner-container .hero-banner-desc {
  padding-top: 7px;
}
.c-hero-component.hero-card.component {
  margin-bottom: 30px;
}

.tenant-fwad .c-hero-component.v-annual-pass-hero .hero-detail-container .hero-banner-shortTitle {
  padding-bottom: 0;
  padding-top: 9px;
  font-family: "Open Sans", Arial, sans-serif;
  line-height: 1.6;
  letter-spacing: 2px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-fwad .c-hero-component.v-annual-pass-hero .hero-detail-container .hero-banner-shortTitle {
    padding-bottom: 0;
    padding-top: 7px;
    line-height: 1.8;
    letter-spacing: 1.5px;
  }
}
.tenant-fwad .c-hero-component.v-annual-pass-hero .hero-detail-container .hero-banner-shortTitle > div {
  font-family: "Norwester", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 33px;
  line-height: 39px;
  letter-spacing: 0.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .tenant-fwad .c-hero-component.v-annual-pass-hero .hero-detail-container .hero-banner-shortTitle > div {
    font-size: 33px;
    line-height: 39px;
    letter-spacing: 0.5px;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-fwad .c-hero-component.v-annual-pass-hero .hero-detail-container .hero-banner-shortTitle > div {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-fwad .c-hero-component.v-annual-pass-hero .hero-detail-container .hero-banner-shortTitle > div {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
body.lang-ar .tenant-fwad .c-hero-component.v-annual-pass-hero .hero-detail-container .hero-banner-shortTitle > div {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 33px;
  line-height: 39px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .tenant-fwad .c-hero-component.v-annual-pass-hero .hero-detail-container .hero-banner-shortTitle > div {
    font-size: 33px;
    line-height: 39px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .tenant-fwad .c-hero-component.v-annual-pass-hero .hero-detail-container .hero-banner-shortTitle > div {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .tenant-fwad .c-hero-component.v-annual-pass-hero .hero-detail-container .hero-banner-shortTitle > div {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
body.lang-ru .tenant-fwad .c-hero-component.v-annual-pass-hero .hero-detail-container .hero-banner-shortTitle > div {
  font-family: "Noto Sans Black", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 39px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .tenant-fwad .c-hero-component.v-annual-pass-hero .hero-detail-container .hero-banner-shortTitle > div {
    font-size: 30px;
    line-height: 39px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .tenant-fwad .c-hero-component.v-annual-pass-hero .hero-detail-container .hero-banner-shortTitle > div {
    font-size: 42px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .tenant-fwad .c-hero-component.v-annual-pass-hero .hero-detail-container .hero-banner-shortTitle > div {
    font-size: 42px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
body.lang-zh .tenant-fwad .c-hero-component.v-annual-pass-hero .hero-detail-container .hero-banner-shortTitle > div {
  font-family: "Norwester", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 700;
  font-size: 33px;
  line-height: 39px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .tenant-fwad .c-hero-component.v-annual-pass-hero .hero-detail-container .hero-banner-shortTitle > div {
    font-size: 33px;
    line-height: 39px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .tenant-fwad .c-hero-component.v-annual-pass-hero .hero-detail-container .hero-banner-shortTitle > div {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .tenant-fwad .c-hero-component.v-annual-pass-hero .hero-detail-container .hero-banner-shortTitle > div {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}

.contact-us .component.c-hero-component.left {
  margin-bottom: 0;
}

.article-detail-page .c-hero-component.left.component {
  margin-bottom: 0;
}

/*
 *  Radius
*/
/*
 * Config
 * @ Variables
 *
*/
/*
 *  Use Defaults
*/
/*
 *  FWAD base Font size
*/
/*
 * H1
*/
/*
 * header fonts mobile devices
 */
/*
 * header fonts tablets(portrait)
 */
/*
 * header fonts tablets(landscape) and small desktop
 */
/*
 * header fonts large desktop
 */
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-1
*/
/*/*
 * body-2
*/
/*
 * body-3
*/
/* media carousel overlay */
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-ar-1
*/
/*/*
 * body-ar-2
*/
/*
 * body-ar-3
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-ru-1
*/
/*/*
 * body-ru-2
*/
/*
 * body-ru-3
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-zh-1
*/
/*/*
 * body-zh-2
*/
/*
 * body-zh-3
*/
/*
* z-index variables
*/
/*
* form placeholder color variables
*/
/*
 * Background Image
 */
/*
  * Line height font
  */
/*
  * Border Radius
  */
/*
  * Box Sizing
  */
/*
  * Box Shadow
  */
/*
  * Border Radius Separate
  */
/*
  * Desktop (PC's)
  */
/*
  * Wide screen Desktop (PC's)
  */
/*
  * Wide screen Desktop (PC's)
  */
/*
  * large screen Desktop (PC's)
  */
/*
  * Wide screen Devices
  */
/*
  * upto Tablet
  */
/*
  * Tablet
  */
/*
  * TabletOnly
  */
/*
  * Mobile
  */
/*
  * Transition
     usage example
       a{
       color: gray;
       @include transition(color .3s ease);
       &:hover {
         color: black;
       }
 }
  */
/*
  * Opacity
  */
/*
  * Clearfix
  */
/*
  * Configure vendor
  */
/*
  * Transform
  */
/**
 *   Use Arrow inside the :after and :before pseudo elements
 *   and change the position as per needed
 *   @include arrows('right', 7px, 7px, #000);
 **/
/**
 *   Background gradient mixin
 **/
/*
  * Flex
  */
/*
 * @ Fonts
 *
*/
/*
 * Base
 * @ Colors
 *
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * Base
 * @ Text
 *
*/
.hero-panel-with-card.component {
  margin-top: 30px;
  margin-bottom: 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .hero-panel-with-card.component {
    margin-top: 0;
  }
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container {
  max-width: 100%;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container {
    width: 98.1818181818%;
    max-width: 1296px;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container {
    max-width: 1366px;
  }
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card {
  position: relative;
  margin: 0;
  width: 100%;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card {
    width: 48.1818181818%;
    max-width: 1296px;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    float: left;
  }
  .lang-ar .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card {
    float: right;
  }
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card + .hero-card {
  top: -16px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card + .hero-card {
    top: 0;
  }
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-image {
  float: none;
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
  width: 86.9047619048%;
  max-width: 667.4285714286px;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  padding-left: 18px;
  padding-right: 17px;
  background-color: #ffffff;
  float: none;
  text-align: center;
  box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.06);
  position: relative;
  top: -56px;
  margin: auto;
  border-radius: 4px;
  padding-top: 24px;
  padding-bottom: 28px;
  min-height: 175px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
.lang-ar .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
  padding-left: 17px;
  padding-right: 18px;
}
@media (min-width: 768px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
    width: 80.6451612903%;
    max-width: 825.8064516129px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    padding-left: 20px;
    padding-right: 22px;
    margin: auto;
    padding-bottom: 27px;
  }
  .lang-ar .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
    padding-left: 22px;
    padding-right: 20px;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
    padding-left: 19px;
    padding-right: 17px;
    top: -86px;
    padding-top: 25px;
  }
  .lang-ar .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
    padding-left: 17px;
    padding-right: 19px;
  }
}
@media only screen and (min-width: 1366px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
    width: 72.311827957%;
    max-width: 740.4731182796px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    padding-left: 40px;
    padding-right: 41px;
    margin: 0 auto;
  }
  .lang-ar .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
    padding-left: 41px;
    padding-right: 40px;
  }
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-title {
  font-family: "Norwester", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  color: #e50000;
}
@media (min-width: 768px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-title {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.5px;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-title {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.5px;
  }
}
@media only screen and (min-width: 1366px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-title {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.5px;
  }
}
body.lang-ar .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-title {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-title {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.5px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-title {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.5px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-title {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.5px;
  }
}
body.lang-ru .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-title {
  font-family: "Noto Sans Black", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-title {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.5px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-title {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.5px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-title {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.5px;
  }
}
body.lang-zh .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-title {
  font-family: "Norwester", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-title {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.5px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-title {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.5px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-title {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.5px;
  }
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  margin-top: 20px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
body.lang-ar .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
body.lang-ru .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
body.lang-zh .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
  font-family: "Open Sans", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media (min-width: 768px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
    margin-top: 15px;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
    margin-top: 13px;
  }
}
@media only screen and (min-width: 1366px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
    margin-top: 17px;
  }
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more {
  margin-top: 19px;
  margin-bottom: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more {
    margin-top: 7px;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more {
    margin-top: 9px;
  }
}
@media only screen and (min-width: 1366px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more {
    margin-top: 19px;
  }
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more .secondary-cta {
  position: relative;
  color: #000000;
  border-bottom: 1px solid #e50000;
  text-decoration: none;
  padding: 0;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  cursor: pointer;
}
@media (min-width: 768px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more .secondary-cta {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more .secondary-cta {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1366px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more .secondary-cta {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
body.lang-ar .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more .secondary-cta {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more .secondary-cta {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more .secondary-cta {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more .secondary-cta {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
body.lang-ru .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more .secondary-cta {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more .secondary-cta {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more .secondary-cta {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more .secondary-cta {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
body.lang-zh .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more .secondary-cta {
  font-family: "Open Sans", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more .secondary-cta {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more .secondary-cta {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more .secondary-cta {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more .secondary-cta:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 12px;
  height: 1px;
  bottom: -1px;
  left: -12px;
  z-index: 1;
  border-bottom: 1px solid #ffffff;
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more .secondary-cta:hover, .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more .secondary-cta:focus {
  color: #000000;
  border: none;
  border-bottom: 1px solid #e50000;
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more .secondary-cta:hover:before, .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more .secondary-cta:focus:before {
  left: 100%;
  transition: all 0.4s ease-in-out;
}
.home-page .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card:last-child {
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .home-page .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card:last-child .card-content {
    margin-bottom: -10px;
  }
}
.home-page .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .home-page .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
    top: 0;
    margin: -56px auto 30px;
  }
}
@media only screen and (min-width: 1024px) {
  .home-page .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
    margin: -86px auto 30px;
  }
}
@media only screen and (min-width: 1366px) {
  .home-page .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
    margin-left: auto;
    margin-right: auto;
  }
  .lang-ar .home-page .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
    margin-left: auto;
    margin-right: auto;
  }
}

.hero-card .c-media-carousel-wrapper {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .hero-card .c-media-carousel-wrapper {
    margin-bottom: 40px;
  }
}